<template>
  <div class="page1" v-loading="loading">
    <div style="text-align: right; margin-left: 8.5%; margin: 20px 0">
      <el-button type="primary" @click="submitData" style="width: 88px">{{ $t('button.preservation') }}</el-button>
      <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
    </div>
    <!--主体内容-->
    <el-form
      label-position="right"
      label-width="110px"
      :model="formInline"
      :rules="rules"
      :ref="'formInline'"
    >
      <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
        <el-select v-model.trim="formInline.operationId" filterable size="15">
          <el-option label="请选择" value=""></el-option>
          <el-option
            :label="value.operationName"
            :value="value.operationId"
            v-for="value in roleList"
            :key="value.operationId"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="content bgFFF">
        <div class="title">入场小票</div>
        <div class="btn">
          <el-button type="text" @click="onShowConfigure(1)">{{
            showList.includes(1) ? "折叠" : "展开"
          }}</el-button
          ><el-button type="text" @click="onDiaShow(1, 'entryTicket')">预览</el-button>
        </div>
        <div class="subtitle" v-show="!showList.includes(1)">
          小票标题：{{ formInline.entryTicket.title }}
        </div>
        <div class="box" v-show="showList.includes(1)">
          <el-form-item label="小票标题" prop="entryTicket.title">
            <el-input
              v-model.trim="formInline.entryTicket.title"
              placeholder="请输入小票标题"
              style="width: 221px"
            ></el-input>
          </el-form-item>
          <el-form-item label="停车信息" prop="entryTicket.parkList">
            <el-checkbox-group v-model="formInline.entryTicket.parkList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('entryTicket', 'plateNumber', 1, 'parkList')"
                >车牌号码</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('entryTicket', 'plateColor', 2, 'parkList')"
                >车牌颜色</el-checkbox
              >
              <el-checkbox
                :label="3"
                @change="onCheckboxChange('entryTicket', 'parkName', 3, 'parkList')"
                >停放路段</el-checkbox
              >
              <el-checkbox
                :label="4"
                @change="onCheckboxChange('entryTicket', 'berthCode', 4, 'parkList')"
                >停放泊位</el-checkbox
              >
              <el-checkbox
                :label="5"
                @change="onCheckboxChange('entryTicket', 'entryTime', 5, 'parkList')"
                >入场时间</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="运营信息" prop="entryTicket.entryOptList">
            <el-checkbox-group v-model="formInline.entryTicket.entryOptList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('entryTicket', 'operationName', 1, 'entryOptList')"
                >运营单位</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('entryTicket', 'pdaManagerCode', 2, 'entryOptList')"
                >收费员编号</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="其它" prop="entryTicket.printTime">
            <el-checkbox v-model="formInline.entryTicket.printTime" :false-label="0" :true-label="1"
              >打印时间</el-checkbox
            >
          </el-form-item>
          <el-form-item label="二维码" prop="entryTicket.codeList">
            <el-checkbox-group v-model="formInline.entryTicket.codeList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('entryTicket', 'innerPayUrl', 1, 'codeList')"
                >场中支付二维码（扫码打开订单详情）</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('entryTicket', 'debtPayUrl', 2, 'codeList')"
                >欠费追缴二维码（扫码打开欠费列表）</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="扫码方式" prop="entryTicket.scanCodeInfo">
            <el-input
              type="textarea"
              :maxlength="60"
              :rows="6"
              style="width: 400px"
              placeholder="请输入扫码方式"
              v-model.trim="formInline.entryTicket.scanCodeInfo"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="温馨提示" prop="entryTicket.remark">
            <el-input
              type="textarea"
              style="width: 400px"
              :maxlength="300"
              :rows="6"
              placeholder="请输入温馨提示"
              v-model.trim="formInline.entryTicket.remark"
            >
            </el-input>
          </el-form-item>
        </div>
      </div>
      <div class="content bgFFF">
        <div class="title">预付费小票</div>
        <div class="btn">
          <el-button type="text" @click="onShowConfigure(2)">{{
            showList.includes(2) ? "折叠" : "展开"
          }}</el-button
          ><el-button type="text" @click="onDiaShow(2, 'preTicket')">预览</el-button>
        </div>
        <div class="subtitle" v-show="!showList.includes(2)">
          小票标题：{{ formInline.preTicket.title }}
        </div>
        <div class="box" v-show="showList.includes(2)">
          <el-form-item label="小票标题" prop="preTicket.title">
            <el-input
              v-model.trim="formInline.preTicket.title"
              placeholder="请输入小票标题"
              style="width: 221px"
            ></el-input>
          </el-form-item>
          <el-form-item label="停车信息" prop="preTicket.parkList">
            <el-checkbox-group v-model="formInline.preTicket.parkList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('preTicket', 'plateNumber', 1, 'parkList')"
                >车牌号码</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('preTicket', 'plateColor', 2, 'parkList')"
                >车牌颜色</el-checkbox
              >
              <el-checkbox
                :label="3"
                @change="onCheckboxChange('preTicket', 'parkName', 3, 'parkList')"
                >停放路段</el-checkbox
              >
              <el-checkbox
                :label="4"
                @change="onCheckboxChange('preTicket', 'berthCode', 4, 'parkList')"
                >停放泊位</el-checkbox
              >
              <el-checkbox
                :label="5"
                @change="onCheckboxChange('preTicket', 'entryTime', 5, 'parkList')"
                >入场时间</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="支付信息" prop="preTicket.payList">
            <el-checkbox-group v-model="formInline.preTicket.payList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('preTicket', 'preMoney', 1, 'payList')"
                >预付金额</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('preTicket', 'payTime', 2, 'payList')"
                >支付时间</el-checkbox
              >
              <el-checkbox
                :label="3"
                @change="onCheckboxChange('preTicket', 'payType', 3, 'payList')"
                >支付方式</el-checkbox
              >
              <el-checkbox
                :label="4"
                @change="onCheckboxChange('preTicket', 'tradeNo', 4, 'payList')"
                >订单编号</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="运营信息" prop="preTicket.entryOptList">
            <el-checkbox-group v-model="formInline.preTicket.entryOptList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('preTicket', 'operationName', 1, 'entryOptList')"
                >运营单位</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('preTicket', 'pdaManagerCode', 2, 'entryOptList')"
                >收费员编号</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="其它" prop="preTicket.printTime">
            <el-checkbox v-model="formInline.preTicket.printTime" :false-label="0" :true-label="1"
              >打印时间</el-checkbox
            >
          </el-form-item>
          <el-form-item label="二维码" prop="preTicket.codeList">
            <el-checkbox-group v-model="formInline.preTicket.codeList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('preTicket', 'innerPayUrl', 1, 'codeList')"
                >场中支付二维码（扫码打开订单详情）</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('preTicket', 'debtPayUrl', 2, 'codeList')"
                >欠费追缴二维码（扫码打开欠费列表）</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="扫码方式" prop="preTicket.scanCodeInfo">
            <el-input
              type="textarea"
              :maxlength="60"
              :rows="6"
              style="width: 400px"
              placeholder="请输入扫码方式"
              v-model.trim="formInline.preTicket.scanCodeInfo"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="温馨提示" prop="preTicket.remark">
            <el-input
              type="textarea"
              style="width: 400px"
              :maxlength="300"
              :rows="6"
              placeholder="请输入温馨提示"
              v-model.trim="formInline.preTicket.remark"
            >
            </el-input>
          </el-form-item>
        </div>
      </div>
      <div class="content bgFFF">
        <div class="title">出场小票</div>
        <div class="btn">
          <el-button type="text" @click="onShowConfigure(3)">{{
            showList.includes(3) ? "折叠" : "展开"
          }}</el-button
          ><el-button type="text" @click="onDiaShow(3, 'exitTicket')">预览</el-button>
        </div>
        <div class="subtitle" v-show="!showList.includes(3)">
          小票标题：{{ formInline.exitTicket.title }}
        </div>
        <div class="box" v-show="showList.includes(3)">
          <el-form-item label="小票标题" prop="exitTicket.title">
            <el-input
              v-model.trim="formInline.exitTicket.title"
              placeholder="请输入小票标题"
              style="width: 221px"
            ></el-input>
          </el-form-item>
          <el-form-item label="停车信息" prop="exitTicket.parkList">
            <el-checkbox-group v-model="formInline.exitTicket.parkList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('exitTicket', 'plateNumber', 1, 'parkList')"
                >车牌号码</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('exitTicket', 'plateColor', 2, 'parkList')"
                >车牌颜色</el-checkbox
              >
              <el-checkbox
                :label="3"
                @change="onCheckboxChange('exitTicket', 'parkName', 3, 'parkList')"
                >停放路段</el-checkbox
              >
              <el-checkbox
                :label="4"
                @change="onCheckboxChange('exitTicket', 'berthCode', 4, 'parkList')"
                >停放泊位</el-checkbox
              >
              <el-checkbox
                :label="5"
                @change="onCheckboxChange('exitTicket', 'entryTime', 5, 'parkList')"
                >入场时间</el-checkbox
              >
              <el-checkbox
                :label="6"
                @change="onCheckboxChange('exitTicket', 'exitTime', 6, 'parkList')"
                >出场时间</el-checkbox
              >
              <el-checkbox
                :label="7"
                @change="onCheckboxChange('exitTicket', 'duration', 7, 'parkList')"
                >停车时长</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="支付信息" prop="exitTicket.payList">
            <el-checkbox-group v-model="formInline.exitTicket.payList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('exitTicket', 'totalMoney', 1, 'payList')"
                >订单总额</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('exitTicket', 'payMoney', 2, 'payList')"
                >已付金额</el-checkbox
              >
              <el-checkbox
                :label="3"
                @change="onCheckboxChange('exitTicket', 'parkDiscountMoney', 3, 'payList')"
                >停车场折扣</el-checkbox
              >
              <el-checkbox
                :label="4"
                @change="onCheckboxChange('exitTicket', 'parkCardMoney', 4, 'payList')"
                >停车卡抵扣</el-checkbox
              >
              <el-checkbox
                :label="5"
                @change="onCheckboxChange('exitTicket', 'couponMoney', 5, 'payList')"
                >优惠券</el-checkbox
              >
              <el-checkbox
                :label="6"
                @change="onCheckboxChange('exitTicket', 'refundMoney', 6, 'payList')"
                >退款金额</el-checkbox
              >
              <el-checkbox
                :label="7"
                @change="onCheckboxChange('exitTicket', 'money', 7, 'payList')"
                >还需支付</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="运营信息" prop="exitTicket.entryOptList">
            <el-checkbox-group v-model="formInline.exitTicket.entryOptList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('exitTicket', 'operationName', 1, 'entryOptList')"
                >运营单位</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('exitTicket', 'pdaManagerCode', 2, 'entryOptList')"
                >收费员编号</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="其它" prop="exitTicket.printTime">
            <el-checkbox v-model="formInline.exitTicket.printTime" :false-label="0" :true-label="1"
              >打印时间</el-checkbox
            >
          </el-form-item>
          <el-form-item label="二维码" prop="exitTicket.codeList">
            <el-checkbox-group v-model="formInline.exitTicket.codeList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('exitTicket', 'debtPayUrl', 1, 'codeList')"
                >查看订单二维码（扫码打开订单详情或欠费列表）</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="扫码方式" prop="exitTicket.scanCodeInfo">
            <el-input
              type="textarea"
              :maxlength="60"
              :rows="6"
              style="width: 400px"
              placeholder="请输入扫码方式"
              v-model.trim="formInline.exitTicket.scanCodeInfo"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="温馨提示" prop="exitTicket.remark">
            <el-input
              type="textarea"
              style="width: 400px"
              :maxlength="300"
              :rows="6"
              placeholder="请输入温馨提示"
              v-model.trim="formInline.exitTicket.remark"
            >
            </el-input>
          </el-form-item>
        </div>
      </div>
      <div class="content bgFFF">
        <div class="title">缴费小票</div>
        <div class="btn">
          <el-button type="text" @click="onShowConfigure(4)">{{
            showList.includes(4) ? "折叠" : "展开"
          }}</el-button
          ><el-button type="text" @click="onDiaShow(4, 'debtTicket')">预览</el-button>
        </div>
        <div class="subtitle" v-show="!showList.includes(4)">
          小票标题：{{ formInline.debtTicket.title }}
        </div>
        <div class="box" v-show="showList.includes(4)">
          <el-form-item label="小票标题" prop="debtTicket.title">
            <el-input
              v-model.trim="formInline.debtTicket.title"
              placeholder="请输入小票标题"
              style="width: 221px"
            ></el-input>
          </el-form-item>
          <el-form-item label="停车信息" prop="debtTicket.parkList">
            <el-checkbox-group v-model="formInline.debtTicket.parkList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('debtTicket', 'plateNumber', 1, 'parkList')"
                >车牌号码</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('debtTicket', 'plateColor', 2, 'parkList')"
                >车牌颜色</el-checkbox
              >
              <el-checkbox
                :label="3"
                @change="onCheckboxChange('debtTicket', 'parkName', 3, 'parkList')"
                >停放路段</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="支付信息" prop="debtTicket.payList">
            <el-checkbox-group v-model="formInline.debtTicket.payList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('debtTicket', 'count', 1, 'payList')"
                >缴纳单数</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('debtTicket', 'shouldMoney', 2, 'payList')"
                >应付金额</el-checkbox
              >
              <el-checkbox
                :label="3"
                @change="onCheckboxChange('debtTicket', 'money', 3, 'payList')"
                >实付金额</el-checkbox
              >
              <el-checkbox
                :label="4"
                @change="onCheckboxChange('debtTicket', 'payType', 4, 'payList')"
                >支付方式</el-checkbox
              >
              <el-checkbox
                :label="5"
                @change="onCheckboxChange('debtTicket', 'payTime', 5, 'payList')"
                >支付时间</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="运营信息" prop="debtTicket.entryOptList">
            <el-checkbox-group v-model="formInline.debtTicket.entryOptList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('debtTicket', 'operationName', 1, 'entryOptList')"
                >运营单位</el-checkbox
              >
              <el-checkbox
                :label="2"
                @change="onCheckboxChange('debtTicket', 'pdaManagerCode', 2, 'entryOptList')"
                >收费员编号</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="其它" prop="debtTicket.printTime">
            <el-checkbox v-model="formInline.debtTicket.printTime" :false-label="0" :true-label="1"
              >打印时间</el-checkbox
            >
          </el-form-item>
          <el-form-item label="二维码" prop="debtTicket.codeList">
            <el-checkbox-group v-model="formInline.debtTicket.codeList">
              <el-checkbox
                :label="1"
                @change="onCheckboxChange('debtTicket', 'debtPayUrl', 1, 'codeList')"
                >查看订单二维码（扫码打开订单详情或欠费列表）</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="扫码方式" prop="debtTicket.scanCodeInfo">
            <el-input
              type="textarea"
              :maxlength="60"
              :rows="6"
              style="width: 400px"
              placeholder="请输入扫码方式"
              v-model.trim="formInline.debtTicket.scanCodeInfo"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="温馨提示" prop="debtTicket.remark">
            <el-input
              type="textarea"
              style="width: 400px"
              :maxlength="300"
              :rows="6"
              placeholder="请输入温馨提示"
              v-model.trim="formInline.debtTicket.remark"
            >
            </el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div style="text-align: center; margin-left: 8.5%; margin: 20px 0">
      <el-button type="primary" @click="submitData" style="width: 88px">{{ $t('button.preservation') }}</el-button>
      <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="50%">
      <EntryTicket v-if="showIndex === 1" :ticketObj="previewObj"></EntryTicket>
      <PreTicket v-if="showIndex === 2" :ticketObj="previewObj"></PreTicket>
      <ExitTicket v-if="showIndex === 3" :ticketObj="previewObj"></ExitTicket>
      <DebtTicket v-if="showIndex === 4" :ticketObj="previewObj"></DebtTicket>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import EntryTicket from "./components/entryTicket.vue";
import PreTicket from "./components/preTicket.vue";
import ExitTicket from "./components/exitTicket.vue";
import DebtTicket from "./components/debtTicket.vue";
export default {
  name: "PdaTicketConfigDetail",
  data() {
    return {
      showList: [1, 2, 3, 4],
      showIndex: 0,
      dialogVisible: false,
      roleList: [],
      isEdit: false,
      total: 0,
      loading: false,
      configId: "",
      previewObj: undefined,
      formInline: {
        operationId: "",
        // 入场
        entryTicket: {
          title: "智慧泊车停车入场凭证",
          plateNumber: 1,
          plateColor: 1,
          parkName: 1,
          berthCode: 1,
          entryTime: 1,
          operationName: 0,
          pdaManagerCode: 0,
          printTime: 0,
          innerPayUrl: 1,
          debtPayUrl: 0,
          scanCodeInfo: "请使用微信/支付宝扫码，查看订单详情。",
          remark: "",
          parkList: [1, 2, 3, 4, 5],
          entryOptList: [],
          codeList: [1],
        },
        // 预付
        preTicket: {
          title: "智慧泊车预付费凭证",
          plateNumber: 1,
          plateColor: 1,
          parkName: 1,
          berthCode: 1,
          entryTime: 1,
          preMoney: 1,
          payTime: 1,
          payType: 1,
          tradeNo: 1,
          operationName: 0,
          pdaManagerCode: 0,
          printTime: 0,
          innerPayUrl: 1,
          debtPayUrl: 0,
          scanCodeInfo: "请使用微信/支付宝扫码，查看订单详情。",
          remark: "",
          parkList: [1, 2, 3, 4, 5],
          payList: [1, 2, 3, 4],
          entryOptList: [],
          codeList: [1],
        },
        // 出场
        exitTicket: {
          title: "智慧泊车出场凭证",
          plateNumber: 1,
          plateColor: 1,
          parkName: 1,
          berthCode: 1,
          entryTime: 1,
          exitTime: 1,
          duration: 1,
          totalMoney: 1,
          payMoney: 1,
          parkDiscountMoney: 1,
          parkCardMoney: 1,
          couponMoney: 1,
          refundMoney: 1,
          money: 1,
          operationName: 0,
          pdaManagerCode: 0,
          printTime: 0,
          debtPayUrl: 1,
          scanCodeInfo: "请使用微信/支付宝扫码，查看订单详情。",
          remark: "",
          parkList: [1, 2, 3, 4, 5, 6, 7],
          payList: [1, 2, 3, 4, 5, 6, 7],
          entryOptList: [],
          codeList: [1],
        },
        // 缴费
        debtTicket: {
          title: "智慧泊车缴费凭证",
          plateNumber: 1,
          plateColor: 1,
          parkName: 1,
          count: 1,
          shouldMoney: 1,
          money: 1,
          payType: 1,
          payTime: 1,
          operationName: 0,
          pdaManagerCode: 0,
          printTime: 0,
          debtPayUrl: 1,
          scanCodeInfo: "请使用微信/支付宝扫码，查看订单详情。",
          remark: "",
          parkList: [1, 2, 3],
          payList: [1, 2, 3, 4, 5],
          entryOptList: [],
          codeList: [1],
        },
      },
      rules: {
        operationId: [
          {
            required: true,
            message: "请选择商户名称",
            trigger: "change",
          },
        ],
        // 入场
        "entryTicket.title": [
          {
            required: true,
            validator: this.validateTitle,
            trigger: "blur",
          },
        ],
        "entryTicket.parkList": [
          {
            required: true,
            message: "请选择停车信息",
            trigger: "change",
          },
        ],
        "entryTicket.codeList": [
          {
            required: true,
            message: "请选择二维码",
            trigger: "change",
          },
        ],
        "entryTicket.scanCodeInfo": [
          {
            required: true,
            message: "请输入扫码方式",
            trigger: "blur",
          },
        ],
        // 预付
        "preTicket.title": [
          {
            required: true,
            validator: this.validateTitle,
            trigger: "blur",
          },
        ],
        "preTicket.parkList": [
          {
            required: true,
            message: "请选择停车信息",
            trigger: "change",
          },
        ],
        "preTicket.codeList": [
          {
            required: true,
            message: "请选择二维码",
            trigger: "change",
          },
        ],
        "preTicket.scanCodeInfo": [
          {
            required: true,
            message: "请输入扫码方式",
            trigger: "blur",
          },
        ],
        "preTicket.payList": [
          {
            required: true,
            message: "请选择支付信息",
            trigger: "change",
          },
        ],
        // 出场
        "exitTicket.title": [
          {
            required: true,
            validator: this.validateTitle,
            trigger: "blur",
          },
        ],
        "exitTicket.parkList": [
          {
            required: true,
            message: "请选择停车信息",
            trigger: "change",
          },
        ],
        "exitTicket.codeList": [
          {
            required: true,
            message: "请选择二维码",
            trigger: "change",
          },
        ],
        "exitTicket.scanCodeInfo": [
          {
            required: true,
            message: "请输入扫码方式",
            trigger: "blur",
          },
        ],
        "exitTicket.payList": [
          {
            required: true,
            message: "请选择支付信息",
            trigger: "change",
          },
        ],
        // 缴费
        "debtTicket.title": [
          {
            required: true,
            validator: this.validateTitle,
            trigger: "blur",
          },
        ],
        "debtTicket.parkList": [
          {
            required: true,
            message: "请选择停车信息",
            trigger: "change",
          },
        ],
        "debtTicket.codeList": [
          {
            required: true,
            message: "请选择二维码",
            trigger: "change",
          },
        ],
        "debtTicket.scanCodeInfo": [
          {
            required: true,
            message: "请输入扫码方式",
            trigger: "blur",
          },
        ],
        "debtTicket.payList": [
          {
            required: true,
            message: "请选择支付信息",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    validateTitle(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入小票标题"));
      } else if (this.getLength(value) > 30) {
        callback(new Error("最多输入30字符"));
      } else {
        callback();
      }
    },
    getLength(str) {
      var len = str.length;
      var reLen = 0;
      for (var i = 0; i < len; i++) {
        if (str.charCodeAt(i) < 27 || str.charCodeAt(i) > 126) {
          // 全角
          reLen += 2;
        } else {
          reLen++;
        }
      }
      return reLen;
    },
    onCheckboxChange(obj, key, value, list) {
      this.formInline[obj][key] = this.formInline[obj][list].includes(value) ? 1 : 0;
    },
    onDiaShow(idx, key) {
      this.previewObj = this.formInline[key];
      this.showIndex = idx;
      this.dialogVisible = true;
    },
    // 折叠  展开
    onShowConfigure(flag) {
      if (this.showList.includes(flag)) {
        this.showList.splice(
          this.showList.findIndex((item) => item === flag),
          1
        );
      } else {
        this.showList.push(flag);
      }
    },
    // 添加
    addList(params) {
      this.$axios
        .post(
          "/acb/2.0/pdaTicketConfigure/save",
          {
            data: params,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.$alert("添加成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          }).then(() => {
            this.$router.go(-1);
          });
        });
    },
    // 修改
    update(params) {
      this.$axios
        .post(
          "/acb/2.0/pdaTicketConfigure/modify?id=" + this.configId,
          {
            data: params,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.$alert("修改成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          }).then(() => {
            this.$router.go(-1);
          });
        });
    },
    submitData() {
      this.$refs["formInline"].validate((valid) => {
        if (valid) {
          let params = this.deleteData(JSON.parse(JSON.stringify(this.formInline)));
          if (this.isEdit) {
            this.update(params);
          } else {
            this.addList(params);
          }
        } else {
          return false;
        }
      });
    },
    // 获取小票详情
    getTicketConfigInfo() {
      this.$axios
        .get("/acb/2.0/pdaTicketConfigure/detail", {
          data: {
            id: this.configId,
          },
        })
        .then((res) => {
          this.loading = false;
          this.deepTraverse(res.value, this.formInline);
          this.initCheckboxList(this.formInline);
        });
    },
    // 遍历小票详情obj
    deepTraverse(obj, target) {
      for (let key in obj) {
        if (
          typeof obj[key] !== "object" &&
          target[key] !== undefined &&
          typeof target[key] !== "object"
        ) {
          target[key] = obj[key];
        }
        if (
          typeof obj[key] === "object" &&
          target[key] !== undefined &&
          typeof target[key] === "object"
        ) { this.deepTraverse(obj[key], target[key]); }
      }
    },
    // 初始化CheckboxList
    initCheckboxList(obj) {
      // 入场
      obj.entryTicket.parkList = [];
      obj.entryTicket.plateNumber && obj.entryTicket.parkList.push(1);
      obj.entryTicket.plateColor && obj.entryTicket.parkList.push(2);
      obj.entryTicket.parkName && obj.entryTicket.parkList.push(3);
      obj.entryTicket.berthCode && obj.entryTicket.parkList.push(4);
      obj.entryTicket.entryTime && obj.entryTicket.parkList.push(5);

      obj.entryTicket.entryOptList = [];
      obj.entryTicket.operationName && obj.entryTicket.entryOptList.push(1);
      obj.entryTicket.pdaManagerCode && obj.entryTicket.entryOptList.push(2);

      obj.entryTicket.codeList = [];
      obj.entryTicket.innerPayUrl && obj.entryTicket.codeList.push(1);
      obj.entryTicket.debtPayUrl && obj.entryTicket.codeList.push(2);

      // 预付
      obj.preTicket.parkList = [];
      obj.preTicket.plateNumber && obj.preTicket.parkList.push(1);
      obj.preTicket.plateColor && obj.preTicket.parkList.push(2);
      obj.preTicket.parkName && obj.preTicket.parkList.push(3);
      obj.preTicket.berthCode && obj.preTicket.parkList.push(4);
      obj.preTicket.entryTime && obj.preTicket.parkList.push(5);

      obj.preTicket.payList = [];
      obj.preTicket.preMoney && obj.preTicket.payList.push(1);
      obj.preTicket.payTime && obj.preTicket.payList.push(2);
      obj.preTicket.payType && obj.preTicket.payList.push(3);
      obj.preTicket.tradeNo && obj.preTicket.payList.push(4);

      obj.preTicket.entryOptList = [];
      obj.preTicket.operationName && obj.preTicket.entryOptList.push(1);
      obj.preTicket.pdaManagerCode && obj.preTicket.entryOptList.push(2);

      obj.preTicket.codeList = [];
      obj.preTicket.innerPayUrl && obj.preTicket.codeList.push(1);
      obj.preTicket.debtPayUrl && obj.preTicket.codeList.push(2);

      // 出场
      obj.exitTicket.parkList = [];
      obj.exitTicket.plateNumber && obj.exitTicket.parkList.push(1);
      obj.exitTicket.plateColor && obj.exitTicket.parkList.push(2);
      obj.exitTicket.parkName && obj.exitTicket.parkList.push(3);
      obj.exitTicket.berthCode && obj.exitTicket.parkList.push(4);
      obj.exitTicket.entryTime && obj.exitTicket.parkList.push(5);
      obj.exitTicket.exitTime && obj.exitTicket.parkList.push(6);
      obj.exitTicket.duration && obj.exitTicket.parkList.push(7);

      obj.exitTicket.payList = [];
      obj.exitTicket.totalMoney && obj.exitTicket.payList.push(1);
      obj.exitTicket.payMoney && obj.exitTicket.payList.push(2);
      obj.exitTicket.parkDiscountMoney && obj.exitTicket.payList.push(3);
      obj.exitTicket.parkCardMoney && obj.exitTicket.payList.push(4);
      obj.exitTicket.couponMoney && obj.exitTicket.payList.push(5);
      obj.exitTicket.refundMoney && obj.exitTicket.payList.push(6);
      obj.exitTicket.money && obj.exitTicket.payList.push(7);

      obj.exitTicket.entryOptList = [];
      obj.exitTicket.operationName && obj.exitTicket.entryOptList.push(1);
      obj.exitTicket.pdaManagerCode && obj.exitTicket.entryOptList.push(2);

      obj.exitTicket.codeList = [];
      obj.exitTicket.debtPayUrl && obj.exitTicket.codeList.push(1);

      // 缴费
      obj.debtTicket.parkList = [];
      obj.debtTicket.plateNumber && obj.debtTicket.parkList.push(1);
      obj.debtTicket.plateColor && obj.debtTicket.parkList.push(2);
      obj.debtTicket.parkName && obj.debtTicket.parkList.push(3);

      obj.debtTicket.payList = [];
      obj.debtTicket.count && obj.debtTicket.payList.push(1);
      obj.debtTicket.shouldMoney && obj.debtTicket.payList.push(2);
      obj.debtTicket.money && obj.debtTicket.payList.push(3);
      obj.debtTicket.payType && obj.debtTicket.payList.push(4);
      obj.debtTicket.payTime && obj.debtTicket.payList.push(5);

      obj.debtTicket.entryOptList = [];
      obj.debtTicket.operationName && obj.debtTicket.entryOptList.push(1);
      obj.debtTicket.pdaManagerCode && obj.debtTicket.entryOptList.push(2);

      obj.debtTicket.codeList = [];
      obj.debtTicket.debtPayUrl && obj.debtTicket.codeList.push(1);
    },
    // 多余数据删除
    deleteData(data) {
      delete data.entryTicket.parkList;
      delete data.entryTicket.entryOptList;
      delete data.entryTicket.codeList;
      delete data.preTicket.parkList;
      delete data.preTicket.payList;
      delete data.preTicket.entryOptList;
      delete data.preTicket.codeList;
      delete data.exitTicket.parkList;
      delete data.exitTicket.payList;
      delete data.exitTicket.entryOptList;
      delete data.exitTicket.codeList;
      delete data.debtTicket.parkList;
      delete data.debtTicket.payList;
      delete data.debtTicket.entryOptList;
      delete data.debtTicket.codeList;
      return data;
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.roleList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  components: {
    EntryTicket,
    PreTicket,
    ExitTicket,
    DebtTicket,
  },
  mounted() {
    this.loading = true;
    this.getRoleList();
    if (this.$route.query.id) {
      this.isEdit = true;
      this.configId = this.$route.query.id;
      this.getTicketConfigInfo();
    }
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.container
  margin 0 auto
  width 400px
  min-height 500px
  border 1px solid #C0CCDA
  padding 20px 0
  display flex
  flex-direction column
  align-items center
.code
  width 200px
  height 200px
  margin-bottom 10px
.tip
  margin-bottom 20px
.item
  width 100%
  margin-bottom 20px
  color rgb(51, 51, 51)
  font-weight 650
  font-style normal
  font-size 16px
  display flex
  align-items center
  padding 0 20px
  box-sizing border-box
.info
  font-weight 650
  font-style normal
  font-size 24px
.infos
  font-weight 400
  font-style normal
  font-size 16px
  color rgb(51, 51, 51)
.label
  display inline-block
  width 90px
  flex-shrink 0
.line
  width 100%
  border-bottom 1px dashed #C0CCDA
  margin-bottom 20px
.pay
  font-weight 650
  font-style normal
  font-size 24px
  margin-bottom 10px
.item:last-child
  margin-bottom 0
  margin-top 20px

.breadcrumb
  height 35px
.content
  position relative
  overflow hidden
  padding 20px
  border 1px solid #C0CCDA
  border-radius 4px
  margin-bottom 20px
.el-tree
  border 0
.el-form-item__content
  margin-left 140p
.title
  font-size 16px
  color #1f2d3d
  font-weight 900
  margin-bottom 20px
.box
  margin-top 20px
.btn
  position absolute
  right 20px
  top 8px
::v-deep .el-dialog__footer
  text-align center
</style>
